/**
 * Composable for setting the page title.
 * Used in layouts. Title can be changed in pages through the `useHead` composable.
 */
export const useProductParams = () => {
  const route = useRoute();

  function extractProductInfo(path: string) {
    let slug = '';
    let id = '';
    let [groupId, attributeId] = (route.query.variant as null|string ?? '').split('-');
    [id, slug] = path.split(/-(.*)/s);
    id = id.replace(/\/[a-z]{2}\//, '');
    id = id.replace(/\//g, '');
    return { id, slug, groupId, attributeId };
  }

  if (!route.params.id) {
    return { id: '', slug: '', groupId: null, attributeId: null };
  }

  return extractProductInfo(route.params.id as string);
};
